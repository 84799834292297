import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Нас | MessPirit
			</title>
			<meta name={"description"} content={"За Терагером: Традиція, пристрасть та непохитна любов до футболу"} />
			<meta property={"og:title"} content={"Про Нас | MessPirit"} />
			<meta property={"og:description"} content={"За Терагером: Традиція, пристрасть та непохитна любов до футболу"} />
			<meta property={"og:image"} content={"https://ua.messpirit.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.messpirit.com/img/188899.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.messpirit.com/img/188899.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.messpirit.com/img/188899.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.messpirit.com/img/188899.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.messpirit.com/img/188899.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.messpirit.com/img/188899.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ua.messpirit.com/img/188899.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Про Нас
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Куратовані колекції MessPirit, свідчать про нашу відданість грандіозному гобелену футболу. Від архівів футбольних трикотажних виробів до найважливіших для відданих шанувальників, кожен предмет відбивається душею гри.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://ua.messpirit.com/img/3.jpg"
						width="320px"
						height="400px"
						object-fit="cover"
						object-position="80%"
						border-radius="15px"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://ua.messpirit.com/img/4.jpg"
						width="320px"
						height="400px"
						border-radius="15px"
						object-fit="cover"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://ua.messpirit.com/img/5.jpg"
						width="320px"
						height="400px"
						border-radius="15px"
						object-fit="cover"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Наша продукція
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Спадкові трикотажні вироби
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Вибирають трикотажні вироби, які відображають багату спадщину футболу.
Як доступні старовинні класики, так і сучасні дизайни.
Створені за допомогою преміум -матеріалів для забезпечення комфорту та довговічності.
Унікальні варіанти справжніх любителів футболу.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Ідеальне взуття
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Різноманітний спектр черевиків, що обслуговують різні місцевості та стилі гри.
Представлені як знакові, так і сучасні дизайни від довірених брендів футбольного взуття.
Розроблений для оптимальних показників та комфорту.
Вибір підходить як для професійних спортсменів, так і для воїнів у вихідні.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Атлетичні та тренувальні основні
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Обладнання, призначене для жорстких тренувань та побудови майстерності.
Довговічність відповідає функціональності, забезпечуючи тривалі показники.
Пристосований як для індивідуальної, так і для командної динаміки.
Вибір, які відображають розвиваються потреби футбольних тренувань.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Куточок прихильників
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Товар, який дозволяє носити футбольне серце на рукаві.
Автентичні пам’ятки, включаючи шарфи, кружки, плакати тощо.
Полюбляйте дух гри, будь то в день матчу, чи в будь -який день.
Ексклюзивні предмети, які резонансувались з любителями футболу.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://ua.messpirit.com/img/6.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						border-radius="15px"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://ua.messpirit.com/img/7.jpg"
						top={0}
						display="block"
						width="100%"
						border-radius="15px"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Для вичерпного списку наших продуктів та останніх оновлень у наших колекціях ми радимо вам зв’язатися з нами електронною поштою.Наша мета - надати вам всебічний огляд, забезпечуючи безшовний терагемний досвід покупок.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});